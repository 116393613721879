import CoverPhoto from './photos/cyberdeck.png';

const photos = [
    {
        src: CoverPhoto,
    },
]

export default {
    COVER_PHOTO: CoverPhoto,
    photos,
}